import { EventEmitter, inject, Injectable } from '@angular/core';
import * as firebaseErrors from '../../../firebase-errors.json';
import { State } from '../store';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, takeUntil, tap } from 'rxjs';
import { ErrorsService } from './errors.service';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { HttpCancelService } from './http-cancel.service';
import { apiURL, Endpoints } from '../configs/http.config';
import { UsersService } from '../pages/users/users.service';
import { environment } from '../../environments/environment';
import { AppEnums } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public api: HttpClient = inject(HttpClient);
  errorService: ErrorsService = inject(ErrorsService);
  state: State = inject(State);
  apiError$ = new EventEmitter<any>();
  shouldAuthPrompt = true;

  constructor() {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
  }

  async pingServer() {
    const url = apiURL(Endpoints.userExists, {
      userId: this.state.store().auth.user?.uid || '',
    });
    return await this.get(url);
  }
  getFirebaseErrorDescription(
    code: string | null | undefined,
    error: any = '',
  ) {
    let message = error?.message || code || 'Unknown error';
    const errors = firebaseErrors as Record<string, string>;
    if (code) {
      message = errors[code] || message;
    }
    return message;
  }
  makePostRequest(
    fullUrl: string,
    body: any = {},
    extraHeaders = {},
    shouldCache = false,
    observe: 'response' | 'body' | 'events' = 'body',
  ): Observable<HttpResponse<ApiResponse | any> | any> {
    if (shouldCache) {
      extraHeaders = {
        ...extraHeaders,
      };
    }
    const req = this.api.post<
      HttpResponse<ApiResponse | any> | ApiResponse | any
    >(fullUrl, body, {
      observe: observe as any,
      headers: {
        Accept: 'application/json',
        ...extraHeaders,
      },
    });
    return req;
  }
  makeGetRequest(
    fullUrl: string,
    params: any = {},
    extraHeaders = {},
    shouldCache = false,
    observe: 'response' | 'body' | 'events' = 'body',
  ): Observable<HttpResponse<ApiResponse | any> | any> {
    if (shouldCache) {
      extraHeaders = {
        ...extraHeaders,
      };
    }
    const req = this.api.get<
      HttpResponse<ApiResponse | any> | ApiResponse | any
    >(fullUrl, {
      params: params,
      observe: observe as any,
      headers: {
        Accept: 'application/json',
        ...extraHeaders,
      },
    });
    return req;
  }

  async post(
    fullUrl: string,
    body: any = {},
    extraHeaders = {},
    shouldCache = false,
    echoServerErrors = false,
  ): Promise<ApiResponse | any> {
    return new Promise((resolve, reject) => {
      console.log(`Making a POST request to ${fullUrl}`);
      const req = this.makePostRequest(
        fullUrl,
        body,
        extraHeaders,
        shouldCache,
        'response',
      );
      req.subscribe({
        next: (res) => {
          if (!res.ok) {
            if (typeof res.body?.Payload === 'string') {
              reject(res.body?.Payload || 'Unknown Server Error');
            } else {
              reject(
                res.body?.message ||
                  res.body?.Data ||
                  `Request failed with error ${res.status}`,
              );
            }
            return;
          }
          const body = res?.body;
          if (body?.Status !== 200) {
            let message = $localize`Sorry, something went wrong`;
            if (
              echoServerErrors ||
              body.Status === 507 ||
              body.Status === 508
            ) {
              message = body?.Payload || message;
            }
            if (typeof body?.Payload === 'string') {
              console.error('Error:', body?.Payload);
              reject(message);
            } else {
              console.error(body.Payload);
              reject(message);
            }
            return;
          }
          resolve(body);
        },
        error: (err: HttpErrorResponse) => {
          if (err?.status === 543) {
            this.errorService.authError$.emit(err);
          } else {
            console.error(
              `Http Request failed with status ${err.status}`,
              err?.error,
            );
            reject(`Sorry, something went wrong.`);
          }
        },
      });
    });
  }

  async get(
    fullUrl: string,
    params: any = {},
    extraHeaders = {},
    shouldCache = false,
  ): Promise<ApiResponse | any> {
    return new Promise((resolve, reject) => {
      const req = this.makeGetRequest(
        fullUrl,
        params,
        extraHeaders,
        shouldCache,
        'response',
      );
      req.subscribe({
        next: (res) => {
          if (!res.ok) {
            reject(
              res.body?.Payload ||
                res.body?.message ||
                res.body?.Data ||
                `Request failed with error ${res.status}`,
            );
            return;
          }
          const body = res?.body;
          if (body?.Status !== 200) {
            reject(body?.Payload || 'Unknown Server Error');
            return;
          }
          resolve(body);
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 543) {
            this.errorService.authError$.emit(err);
          } else {
            if (err.error?.Payload) {
              reject(`${err.error?.Status}: ${err.error?.Payload}`);
            }
            reject(
              err.message ||
                err.error ||
                `Http Request failed with status ${err.status}`,
            );
          }
        },
      });
    });
  }
  async put(fullUrl: string, body: any = {}): Promise<ApiResponse | any> {
    return new Promise((resolve, reject) => {
      this.api
        .put<ApiResponse>(fullUrl, body, {
          observe: 'response',
          headers: {
            Accept: 'application/json',
          },
        })
        .subscribe({
          next: (res) => {
            if (!res.ok) {
              reject(
                res.body?.Payload ||
                  res.body?.message ||
                  res.body?.Data ||
                  `Request failed with error ${res.status}`,
              );
              return;
            }
            const body = res?.body;
            if (body?.Status !== 200) {
              reject(body);
              return;
            }
            resolve(body);
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 543) {
              this.errorService.authError$.emit(err);
            } else {
              reject(
                err.message ||
                  err.error ||
                  `Http Request failed with status ${err.status}`,
              );
            }
          },
        });
    });
  }

  uploadAzureImages(
    url: string,
    file: File,
    formFileName: string,
    contentType: string,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append(formFileName, file);
    const blob = new Blob([file], { type: contentType });
    const req = new HttpRequest('PUT', url, blob, {
      headers: new HttpHeaders({
        'x-ms-blob-type': 'BlockBlob',
        // 'x-ms-blog-content-type': contentType,
        'Content-Type': contentType,
      }),
      reportProgress: true,
      responseType: 'json',
    });
    return this.api.request(req);
  }
  handleAuthError(error: any) {
    this.apiError$.emit(error);
  }
  getTimeSinceLastCall() {
    const lastCall = this.state.store().auth.lastApiResponse;
    if (!lastCall) return null;
    const last = dayjs(lastCall);
    const minutes = dayjs().diff(last, 'minutes');
    // if minutes > 9 then trigger an event to ask the user if they are still there.
    if (minutes >= environment.SESSION_LIFETIME_MINUTES - 1) {
      this.shouldAuthPrompt = false;
      this.errorService.authPrompt$.emit(
        $localize`It seems you have been idle for a while. Are you still there?`,
      );
    }
    return minutes;
  }
  getHumanTimeSinceLastCall() {
    const lastCall = this.state.store().auth.lastApiResponse;
    if (!lastCall) return null;
    const minutes = dayjs
      .duration(this.getTimeSinceLastCall() || 0, 'minutes')
      .humanize();
    return minutes;
  }
}

export interface ApiResponse {
  Status: number;
  Message: string;
  Payload: any;
  data?: any;
  status?: number;
  message?: string;
  payload?: any;
  MessageToClient?: any;
  Data: any;
}

export interface DataApiResponse {
  data: [
    {
      month?: string;
      counts: number;
      Date?: string;
      Cover_Type?: string;
      amount?: number;
      Bouquet_Name?: string;
      Travel_reason?: string;
      Underwriter?: string;
      DAU?: number;
      MAU?: number;
      WAU?: number;
    },
  ];
}

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  // modify request
  const store = inject(State).store();
  const token = store.auth?.idToken || '';
  const httpCancel = inject(HttpCancelService);
  const errorService = inject(ErrorsService);
  const users = inject(UsersService);
  const userRoles = users.getUserRolesList(store.apiUser);
  const userFirstRole = userRoles?.length ? userRoles[0] : 'admin';

  // If not admin, pass the header ModuleID and channel-org
  // If you are admin, skip the above two headers

  req = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ModuleID: store?.tenant?._id || '',
      'fba-tenant':
        store?.tenant?.fbaTenant || environment.DEFAULT_FBA_TENANT || '',
      SKEY: `${token}`,
      'channel-platform': 'admin',
      'channel-org': store?.tenant?._id || environment.APP_DEFAULT_TP || '',
      'channel-actor': userFirstRole,
      'channel-tenant':
        store.tenant?.channel?.channel_tenant ||
        environment.DEFAULT_FBA_TENANT ||
        AppEnums.PartnerIdMTEK,
    } as Record<string, any>,
  });
  if (req.headers.has('apiKey') && req.headers.get('apiKey')) {
    // remove SKEY
    req = req.clone({
      setHeaders: {
        SKEY: '',
      },
    });
  }
  return next(req)
    .pipe(takeUntil(httpCancel.onCancelPendingRequests()))
    .pipe(
      tap((event) => {
        if (event.type === HttpEventType.Sent) {
          // An exception to this is the userexists endpoint:
          if (
            req.url.includes(
              apiURL(Endpoints.userExists.replace(':userId', '')),
            ) ||
            req.url.includes(apiURL(Endpoints.listThirdParties)) ||
            req.url.includes(apiURL(Endpoints.recover2fa))
          ) {
            console.log(`This one is allowed through, so relax.`);
          } else {
            // check the last call
            if (!errorService.isWithinSessionLifetime()) {
              console.log(
                `You have been idle for more than the session lifetime allows. Logging you out.`,
              );
              httpCancel.cancelPendingRequests();
              errorService.authError$.emit('Session expired');
            }
          }
        }
      }),
    );
}
export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  // return next(req);
  const errorService = inject(ErrorsService);
  const state = inject(State);
  const httpCancel = inject(HttpCancelService);
  return next(req)
    .pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          const body = event.body as ApiResponse;
          if (
            event.status === 543 ||
            (body?.Status === 543 && body?.Payload?.includes('token'))
          ) {
            console.log('Ready to log you out.');
            httpCancel.cancelPendingRequests();
            errorService.authError$.emit(event);
          } else if (event.status > 299 || body?.Status > 299) {
            console.log('There was a HTTP error');
            // httpCancel.cancelPendingRequests();
            errorService.apiError$.emit(event);
          } else {
            const body = event.body as any;
            if (body?.Status > 299) {
              httpCancel.cancelPendingRequests();
              errorService.apiError$.emit(body);
            } else if (body?.Status >= 200 && body?.Status < 300) {
              state.store.update((store) => {
                return {
                  ...store,
                  auth: {
                    ...store.auth,
                    lastApiResponse: dayjs().toDate().getTime(),
                  },
                };
              });
              // success
            }
          }
        }
      }),
    )
    .pipe(takeUntil(httpCancel.onCancelPendingRequests()));
}
