export const environment = {
  production: false,
  staging: true,
  apiBaseUrl: 'https://staging.hillcroftinsurance.com',

  firebaseConf: {
    apiKey: 'AIzaSyDYZcTmUhossqtk49Ok7IAVv70mbtRrb8g',
    authDomain: 'mtek-9fb22.firebaseapp.com',
    databaseURL: 'https://mtek-9fb22.firebaseio.com',
    projectId: 'mtek-9fb22',
    storageBucket: 'mtek-9fb22.appspot.com',
    messagingSenderId: '225875384266',
    appId: '1:225875384266:web:657f5e6e49b6cd16',
  },

  testCredentials: {
    username: 'test',
    password: 'test',
  },
  CORE_NTSA_API_URL: 'https://staging.hillcroftinsurance.com/ntsa/graphql',
  CORE_API_URL_LIST: 'https://staging.hillcroftinsurance.com/ntsa',
  CORE_API_URL: 'https://staging.hillcroftinsurance.com/core',
  REPORTS_API_URL: 'https://reportsstaging.hillcroftinsurance.com/api',
  PREMIUM_API_URL: 'https://staging.hillcroftinsurance.com/ip',
  MPESA_API_URL: 'https://staging.hillcroftinsurance.com/mpesa',
  OFFLINE_PAYMENTS_API_URL: 'https://staging.hillcroftinsurance.com/mpesa',
  SELL_API_URL: 'https://staging.hillcroftinsurance.com/upxsell',
  CLAIMS_API_URL: 'https://staging.hillcroftinsurance.com/claims',
  VALUATION_API_URL: 'https://staging.hillcroftinsurance.com/valuation',
  MEDICAL_API_URL: 'https://staging.hillcroftinsurance.com/medical',
  IPF_API_URL: 'https://staging.hillcroftinsurance.com/ipf',
  MOTOR_API_URL: 'https://staging.hillcroftinsurance.com/motor',
  TRAVEL_API_URL: 'https://staging.hillcroftinsurance.com/travel',
  DOMESTIC_API_URL: 'https://staging.hillcroftinsurance.com/domestic',
  PA_API_URL: 'https://staging.hillcroftinsurance.com/pa',
  GOLF_API_URL: 'https://staging.hillcroftinsurance.com/golf',
  EVACUATION_API_URL: 'https://staging.hillcroftinsurance.com/evacuation',
  DATA_API_URL: 'https://staging.hillcroftinsurance.com/data',
  PAYMENTS_API_URL: 'https://staging.hillcroftinsurance.com/mpesa/api',
  KMS_API_URL: 'https://kms.hillcroftinsurance.com',
  AZURE_BLOB_MTEK_UPLOADS_URL:
    'https://hillcroftblobstoragev2.blob.core.windows.net/mtek-uploads',
  ADMIN_MODULE_ID: 'mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007',
  CDN_BASE_URL: 'https://mtekcdnstoragev2.blob.core.windows.net/admin',
  MIXPANEL_PROJECT_TOKEN: 'e8deeb2495fec1e6cb8b4560f8a030e1',
  LEADS_GENERATED_URL:
    'https://staging.hillcroftinsurance.com/data/leads_generated',
  DS_API_URL: 'https://staging.hillcroftinsurance.com/ds',
  LIFE_API_URL: 'https://staging.hillcroftinsurance.com/life',
  CDN_BASE_SAS_TOKEN:
    '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwlacuptf&se=2025-12-31T20:59:59Z&st=2025-01-01T03:08:54Z&spr=https&sig=z%2BGYHQjqRc9RVhrriB%2BhhtTHJO%2BgL%2Fz600V6rvC3Ny0%3D',
  THIRD_PARTIES_CDN_URL:
    'https://mtekcdnstoragev2.blob.core.windows.net/admin/thirdparties_dev.json',
  DATA_ANALYTICS_API_URL: 'https://staging.hillcroftinsurance.com/analytics',
  KYOSK_WEB_URL: 'https://kyoskdev.hillcroftinsurance.com',
  GOLF_WEBSOCKET_URL: 'wss://staging.hillcroftinsurance.com/ws-golf/getquotes',
  SESSION_LIFETIME_MINUTES: 10,
  APP_TITLE: 'mTek Admin Portal',
  APP_API_KEY: 'BO+P0SHY2HerbqDtEaC3ug==',
  APP_DEFAULT_TP: 'mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007',
  ENFORCE_MFA: true,
  USE_THIRD_PARTY_FBA_TENANT: true,
  DEFAULT_FBA_TENANT: null,
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoiaGlsbGNyb2Z0LW1hcGJveCIsImEiOiJjbTVqeml2NmkwZTU1Mmtxb25yYTM2dHZ0In0.xkete2NPW1NjLJE5Fytvow',
};
