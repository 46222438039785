import { inject, Injectable } from '@angular/core';
import { ApiUser, State } from '../store';
import { Router } from '@angular/router';
import { ApiResponse, HttpService } from './http.service';
import { LayoutService } from './layout.service';
import { ErrorsService } from './errors.service';
import { apiURL, Endpoints } from '../configs/http.config';
import { MultiFactorError } from '@angular/fire/auth';
import { forkJoin, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  state: State = inject(State);
  router: Router = inject(Router);
  http: HttpService = inject(HttpService);
  layout: LayoutService = inject(LayoutService);
  errorService: ErrorsService = inject(ErrorsService);

  isAuthenticated() {
    if (!this.state.store().auth.idToken) {
      console.log('No idToken in store, Not authenticated');
      return false;
    }
    if (!this.state.store().auth.user) {
      console.log('No user in store, Not authenticated');
      return false;
    }

    // if (!this.state.store().auth.isAuthenticated) {
    //   console.log('isAuthenticated is not set, Not authenticated');
    //   return false;
    // }

    if (!this.state.store().apiUser) {
      console.log('No apiUser in store, Not authenticated');
      return false;
    }

    if (!this.errorService.isWithinSessionLifetime()) {
      console.log('Session expired, Not authenticated');
      return false;
    }

    return (
      !!this.state.store().auth.idToken &&
      !!this.state.store().auth.user &&
      // !!this.state.store().auth.isAuthenticated &&
      !!this.state.store().apiUser &&
      this.errorService.isWithinSessionLifetime()
    );
  }

  isWaitingForGoogle() {
    return this.state.store().auth.waitingForGoogle;
  }

  waitForGoogle() {
    this.state.store.update((state) => {
      return {
        ...state,
        auth: {
          ...state.auth,
          waitingForGoogle: true,
        },
      };
    });
  }

  stopWaitingForGoogle() {
    this.state.store.update((state) => {
      return {
        ...state,
        auth: {
          ...state.auth,
          waitingForGoogle: false,
        },
      };
    });
  }

  getMfaStore() {
    return this.state.store().mfa;
  }

  requiresMfa() {
    return !!this.state.store().mfa.mfaResolver;
  }

  setHasOTP(hasOtp: boolean) {
    this.state.store.update((state) => {
      state.mfa.hasOtp = hasOtp;
      return state;
    });
  }

  storeMfaCurrentUser(mfaError: MultiFactorError) {
    const customData = mfaError?.customData as {
      appName: string;
      operationType: 'signIn' | 'reauthenticate' | string;
      tenantId: string;
      _serverResponse: any;
    };
    const serverResponse = customData?._serverResponse;
    this.state.store.update((state) => {
      state.mfa.currentUser = serverResponse?.localId;
      return state;
    });
  }

  logout() {
    console.log('Signing Out');
    // this.errorService.authError$.emit(null);
    this.state.store.update((state) => {
      state.auth.user = null;
      state.auth.idToken = null;
      state.auth.accessToken = null;
      state.auth.waitingForGoogle = false;
      state.auth.lastApiResponse = null;
      state.apiUser = null;
      state.mfa.mfaResolver = null;
      state.mfa.hasOtp = false;
      state.mfa.currentTotpSecret = null;
      state.auth.isAuthenticated = false;

      return state;
    });
  }
  setProfile(user: ApiUser | any | null) {
    this.state.store.update((state) => {
      state.apiUser = user;
      state.auth = { ...state.auth, isAuthenticated: !!user };
      return state;
    });
  }

  rememberLastUrl(url: string) {
    console.log('Remembering last URL:', url);
    this.state.store.update((state) => {
      state.lastUrl = url;
      return state;
    });
  }
  getLastUrl() {
    console.log('Last known URL:', this.state.store().lastUrl);
    return this.state.store().lastUrl || '/';
  }

  async fetchAccount(idToken?: string) {
    const headers: any = {};
    if (idToken) {
      headers['SKEY'] = idToken;
    }
    return this.http.makeGetRequest(
      apiURL(Endpoints.userExists, {
        userId: this.state.store().auth.user?.uid || '',
      }),
      {},
      headers,
    );
  }

  async fetchProfile(idToken?: string) {
    const headers: any = {};
    if (idToken) {
      headers['SKEY'] = idToken;
    }
    return new Promise((resolve, reject) => {
      this.http
        .makeGetRequest(
          apiURL(Endpoints.userExists, {
            userId: this.state.store().auth.user?.uid || '',
          }),
        )
        .subscribe({
          next: (res: ApiResponse | any) => {
            const data: any = res;
            if (data?.Status > 299) {
              this.errorService.authError$.emit(data?.Payload);
              reject(data?.Payload ?? data?.error);
            }
            // check if the user has client

            this.setProfile(data?.Payload);
            // TODO: Set permissions based on roles
            console.log('Setting permissions');
            this.fetchPermissions(data.Payload)
              .then(() => {
                console.log('Permissions set');
                resolve(data.Payload);
              })
              .catch((err) => reject(err));
          },
          error: (err: Error | any) => {
            console.log(err);
            this.state.store.update((store) => {
              store.auth.lastApiResponse = null;
              store.auth = { ...store.auth, isAuthenticated: false };
              return store;
            });
            this.setProfile(null);
            this.errorService.authError$.emit(err);
            reject(err?.error?.Payload || err?.message);
          },
        });
    });
  }

  getUserRoles(user: ApiUser) {
    const rolesArray = user?.roles || [];
    let roles = [];
    if (rolesArray) {
      roles = rolesArray.reduce((acc: any[], obj: any) => {
        const key = Object.keys(obj)[0];
        if (obj[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
    }
    // remove duplicates
    roles = [...new Set(roles)];
    return roles;
  }

  async fetchPermissions(user: ApiUser) {
    const tasks$: Observable<ITask[]>[] = [];
    const roles = this.getUserRoles(user);
    roles.forEach((role) => {
      tasks$.push(
        this.http
          .makePostRequest(apiURL(Endpoints.listRbacTasks), {
            'roles.role': role,
            fields: ['task'],
          })
          .pipe(
            map((res) => {
              return res.Payload;
            }),
          ),
      );
    });

    forkJoin(tasks$).subscribe({
      next: (res) => {
        const tasks = res.flat().map((task: ITask) => task.task);
        // remove duplicates
        const uniqueTasks = [...new Set(tasks)];
        this.setPermissions(uniqueTasks);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  setPermissions(permissions: string[]) {
    // save to session storage
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
  }

  getPermissions(): string[] {
    const permissions = sessionStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  hasPermission(perm: string): boolean {
    return this.getPermissions().includes(perm);
  }

  async manualLogin(email: string, password: string) {
    return new Promise((resolve, reject) => {
      fetch(apiURL(Endpoints.auth.signIn), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => {
          if (!response.ok) {
            reject('Could not login');
          }
          response.json().then((res) => {
            if (res.Status !== 200) {
              reject(res.Payload);
            }
            if (res.Payload?.error) {
              console.error(res.Payload);
              reject(res.Payload.error);
            }
            const token = res.Payload?.idToken;
            if (!token) {
              reject('No Token found');
            }
            const user = res.Payload as any;
            user.uid = res.Payload.localId;
            // Set the token to state
            this.state.store.update((state) => {
              state.auth.idToken = token;
              state.auth.user = user;
              state.auth.isAuthenticated = true;
              state.auth.lastApiResponse = new Date().getTime();
              return state;
            });
            // fetch the profile, page state, themes
            this.fetchProfile(token)
              .then(() => {
                resolve(res.Payload);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export interface ITask {
  _id: string;
  task: string;
}
