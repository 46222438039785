import { environment } from '../../environments/environment';

export const Endpoints = {
  themes:
    'https://raw.githubusercontent.com/coolsam726/mock-api/0.0.12/theme.json',
  tenants:
    'https://raw.githubusercontent.com/coolsam726/mock-api/0.0.12/tenants.json',
  userExists: '/isuserexist/:userId',
  auth: {
    signIn: '/signinwithemailpassword',
  },
  listApiKey: '/listapikey',
  generateApikey: '/genapikey',
  removeApiKey: '/removerapikey',
  recover2fa: '/recover2fa',
  listCurrencies: '/listcurrency',
  addCurrency: '/addcurrency',
  removeCurrency: '/removecurrency',
  updateCurrency: '/updatecurrency',
  listUnderwriters: '/listInsuranceUnderwriters',
  addUnderwriter: '/addInsuranceUnderwriters',
  getUnderwriter: '/getInsuranceUnderwriter',
  updateUnderwriter: '/updateInsuranceUnderwriters',
  toggleUnderwriter: '/toggleUnderWriter',
  removeUwProduct: '/removeUnderWriterProduct',
  addUwToProduct: '/addNewUnderWriterToProduct',
  listUwContacts: '/getunderwritercontacts',
  addUwContact: '/addunderwritercontact',
  removeUwContact: '/removeundewritercontact',
  listProductCommissions: '/listProductCommissions',
  updateProductCommissions: '/updateProductCommissions',
  addProductCommissions: '/addProductCommissions',
  addUwBusinessRule: '/addrule',
  listUwBusinessRules: '/listrule',
  updateUwBusinessRule: '/updaterule',
  collections: '/collections',

  policyStats: '/policystats',
  listProducts: '/listProducts',
  listThirdParties: '/listThirdParties', // POST
  addThirdParties: '/addThirdParties',
  updateThirdParties: '/updateThirdParties',
  mapTpReferralCode: '/addreferralcodetpmapping',
  addNewProductToThirdParty: '/addNewProductToThirdParty',
  removeProductFromThirdParty: '/removeProductFromThirdParty',

  listMotorPolicyNumberAllocation: '/listpolicynoallocation', // POST
  listProductUnderwriters: '/listUnderWriterProducts', // POST
  getPolicyReport: '/policy_report/', // GET
  signupStats: '/userstatsforsignup',
  getUserStats: '/getUserStats',
  adminAggregate: '/adminaggregate',
  adminUpdateUser: '/adminupdateuser',
  listCollectionRecords: '/listcollectionrecord',
  addPolicyNoAllocations: '/addpolicynoallocation',
  listRoles: '/listrole',
  addRole: '/addrole',
  removeRole: '/removerole',
  updateUserRoles: '/updateRole',
  listRbacTasks: '/listrbactask',
  listTasks: '/listtasks',
  addRbacTask: '/addrbactask',
  removeRbacTask: '/removerbactask',
  listUsers: '/listUsers',
  createUser: '/createuser',
  getUserDetails: '/getUserDetailsByFeduid',
  resetPassword: '/resetpassword',
  disableUser: '/disableuser',

  pushNotification: '/pushnotification',
  listNotifications: '/listnotifications',
  //Heatmap
  getDriverGPS: '/getDriverGPS',
  // Leads
  heatwaveLeadStats: '/heatwaveleads',
  hotLeadStats: '/hotleads',
  warmLeadStats: '/warmleads',
  lookoutLeadStats: '/lookoutleads',

  hbSalesAgentUsers: '/fetchHBSalesAgentsUsers',

  analyticsPataPesaCommissionWithdrawal: '/patapesa-commission-withdrawal',
  coreGetFirebaseTopics: '/getfirebasetopics',

  promoCodes: {
    baseUrl: environment.SELL_API_URL,
    listPromoCode: '/listpromocode',
    addPromoCode: '/addpromocode',
    removePromoCode: '/removepromocode',
  },
  policies: {
    baseUrl: environment.CORE_API_URL,
    listPolicies: '/adminlistpolicies',
    searchPolicies: '/adminsearchpolicies',
    dmvicGetStocks: '/dmvicgetstocks',
    dmviccheckForDoubleInsurance: '/dmviccheckForDoubleInsurance',
    dmvicgetcertificate: '/dmvicgetcertificate',
    dmviccancelcertificate: '/dmviccancelcertificate',
    listPolicyNoAllocations: '/listpolicynoallocation',
    dmvicIssueCertificate: '/dmvicissueCertificate',
    issuePolicyCert: '/issuepolicycert',
    updatePolicyNoAllocation: '/updatepolicynoallocation',
    dmvicVerifyLogbook: '/dmvicverifylogbook',
    setupPolicyUwDeclaration: '/setpolicyuwdecleration',
    policyDocTypes: '/policydoctypes.json',
    saveQuestions: '/savequestions',
    saveQuoteManually: '/savequotemanually',
    addMemberToQuote: '/adminaddmembertoquote',
  },
  medical: {
    baseUrl: environment.MEDICAL_API_URL,
    listBouquets: '/listbouquet',
    listAddons: '/listaddons',
  },
  payments: {
    baseUrl: environment.PAYMENTS_API_URL,
    listPaymentLogs: '/listpaymentlogs',
    paymentLogStatsApi: '/paymentlogstats',
    adminUpdateRemittance: '/adminUpdateRemittance',
    adminApiKeys: '/adminListApiKeys',
    bulkUpdateRemmittances: '/bulkUpdateRemmittances',
    reversePayments: '/reversepayments',
  },
  ipf: {
    baseUrl: environment.IPF_API_URL,
    fetchGenericStats: '/fetchPaymentPlanStatsGeneric',
    ipfStats: '/ipfstats',
  },
  domestic: {
    baseUrl: environment.DOMESTIC_API_URL,
    listGadgetRepairShops: '/listgadgetrepairshop',
    adminUpdateGadgetClaims: '/adminUpdateGadgetClaims',
    listGadgetNatureOfClaims: '/listgadgetnatureofclaims',
  },
  admin: {
    getQuestions: '/admingetquestions',
    updateQuestions: '/adminUpdateQuestions',
    updateQuestionsGeneral: '/adminUpdateQuestionsGeneral',
    listQuote: '/adminlistquote',
  },
  akiDmvicUnderwriters: '/aki_dmvic_underwriters.json',
  claims: {
    baseUrl: environment.CLAIMS_API_URL,
    saveAccidentReportCause: '/reportAccident/saveAccidentReportCause',
    updateAccidentReportCause: '/reportAccident/updateAccidentReportCause',
    listMotorClaims: '/listMotorClaim',
    getClaim: '/getClaim',
    listGadgetClaims: '/adminListGadgetClaims',
    updateGadgetClaim: '/adminUpdateGadgetClaims',
    updateClaimProgress: '/updateClaimProgress',
    setCarAssessor: '/setcarassessor',
    fileGadgetClaim: '/claimgadget',
    transferPolicy: '/transferpolicy',
  },
  motor: {
    baseUrl: environment.MOTOR_API_URL,
    getVehicleMakes: '/vehicle/makes',
    getVehicleModels: '/vehicle/models',
  },
  vehiclesCdn: {
    baseUrl: 'https://vpic.nhtsa.dot.gov/api/vehicles',
    getModelsForMake: '/getmodelsformake/:make',
    getModelsByMakeId: '/GetModelsForMakeId/:makeId',
    getAllMakes: '/getallmakes',
  },
  financials: {
    revenueReport: 'financials-revenue-report',
  },
  valuations: {
    baseUrl: environment.VALUATION_API_URL,
    listValuers: '/listvaluer',
    assignValue: '/assignmotorquotetovaluer',
  },
} as const;

export type EndpointKeys = keyof typeof Endpoints;

export function apiURL(
  endpoint: string,
  replacements: Record<string, string> = {},
  baseUrl: string | null = null,
) {
  if (Object.values(replacements).length) {
    Object.keys(replacements).forEach((key) => {
      endpoint = endpoint.replace(`:${key}`, replacements[key]);
    });
  }
  if (!baseUrl) {
    baseUrl = environment.CORE_API_URL;
  }
  return (
    (baseUrl?.replace(/\/$/, '') || '') + '/' + endpoint.replace(/^\//, '')
  );
}
